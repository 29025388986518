.homePageNav {
  height: 10%;
}

.homePageMain {
  color: #676767;
  display: flex;
  align-items: center;

  height: 80%;
  padding: 0 10%;
  padding-bottom: 48px;
  width: 75%;
  min-height: 480px;
}

.homePageMain h1 {
  font-size: 61px;
  font-weight: 500;
  line-height: 1.08;
  margin-bottom: 48px;
}

.homePageFooter {
  height: 10%;

  width: 75%;
}

.getsImproved {
  color: #FF6924;
}

.desc {
  font-size: 20px;
}
