.timelogDay {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #FCFCFC;
  border: 1px solid #C2C2C2;
  border-radius: 8px;
  padding: 16px;
  margin: 8px;
  width: fit-content;
}

.timelogDayDarkMode {
  background-color: #0A0A0A;
}

.heading {
  display: grid;
  grid-template-columns: 267px 150px;
  grid-template-rows: repeat(1, 1fr);

  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;

  padding-top: 8px;
  padding-bottom: 24px;
}

.date {
  margin-left: 22px;  /* Need this because of colored dot */
  justify-self: start;

  color: #676767;
  font-size: 16px;
  font-weight: 500;
}

.addIcon {
  margin-left: 35px;

  width: 20px;
  height: 20px;
  fill: #525252;
  box-sizing: content-box;
  padding: 4px;
  border-radius: 8px;

  position: relative;

  transition: all 0.25s;
}

.addIcon:hover {
  fill: #292929;
}

.addIconDarkMode {
  fill: #E0E0E0
}

.addIconDarkMode:hover {
  fill: #C2C2C2
}

.timelogDayColumns {
  display: flex;
  align-items: center;

  color: #A3A3A3;
  font-size: 13px;
  padding-bottom: 6px;
  border-bottom: 1px solid #C2C2C2;
}

.elCategory {
  margin-left: 22px;  /* Need this because of colored dot */
  width: 120px;
  padding-right: 4px;
}

.elDescription {
  width: 160px;
}

.timeElements {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 112px;
}

.timelogItems {
  margin-top: 6px;
}

/* Tooltips */
.addIcon .addTimelogTooltip {
  white-space: nowrap;
  text-align: center;
  top: -52px;
  left: -61px;
  padding: 8px 12px;
  color: #525252;
  background-color: #F0F0F0;
  border: 1px solid #C2C2C2;
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  z-index: 1000;

  display: none;
}

.addIcon:hover .addTimelogTooltip {
  display:block;
}

.addIcon .addTimelogTooltip i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  width: 30px;
  height: 15px;
  overflow: hidden;
}

.addIcon .addTimelogTooltip i::after {
  content:'';
  position:absolute;
  width: 8px;
  height: 8px;
  left: 50%;
  transform: translate(-50%,-50%) rotate(45deg);
  background-color: #F0F0F0;
  border: 1px solid #C2C2C2;
}

