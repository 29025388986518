.main {
  background-image: linear-gradient(to right, rgba(36, 186, 255, 0.12), rgba(255, 105, 36, 0.12));

  justify-content: flex-start;
  height: auto;
  min-height: 100vh;
}

.mainDarkMode {
  background-image: linear-gradient(to right, rgba(36, 186, 255, 0.05), rgba(255, 105, 36, 0.04));
}

.auth {
  display: flex;
  justify-content: center;
}

.authInner {
  padding-top: 16vh;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  max-width: 350px;
}

.authInnerContainer {
  background-color: #FCFCFC;
  padding: 32px 24px;
  border: 1px solid #C2C2C2;
  border-radius: 1.25rem;
}
