.heading {
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 24px;
}

.text {
  color: #676767;
  padding-bottom: 4px;
}

.email {
  font-weight: 600;
  padding-bottom: 12px;
}

.confirmation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  padding-top: 8px;
  padding-bottom: 8px;
}

.checkbox {
  accent-color: #FF2424;
}

.checkbox:hover {
  cursor: pointer;
}

.confirmationText {
  color: #A3A3A3;
}

.restoreText {
  padding-top: 8px;
  padding-bottom: 12px;
  font-size: 12px;
  font-weight: 500;
  color: #C2C2C2;
}

.btnDeleteUnconfirmed,
.btnDeleteUnconfirmed:hover {
  cursor: not-allowed;
  background-color: #FF7B7B;
  border: 1px solid #FF7B7B;
}
