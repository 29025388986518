.navAuthBtns {
  text-decoration: none;
  display: flex;

  gap: 8px;
}

.navAuthBtnsBtn {
  padding: 4px 8px;
  border-radius: 50px;
  border: 1px solid #C2C2C2;

  transition: all 0.25s;
}

.navAuthBtnsBtn:hover {
  color: #FFF;
  background-color: #676767;
  cursor: pointer;

  transition: all 0.25s;
}

.navAuthBtnsBtnDarkMode {
  color: #E7E7E7;
  background-color: #676767;

  border: 1px solid #E7E7E7;
}

.navAuthBtnsBtnDarkMode:hover {
  color: #3D3D3D;
  background-color: #E7E7E7;

  border: 1px solid #E7E7E7;
}

.navAuthBtnsLoginDarkMode {
  background-color: #141414;
}

.navAuthBtnsSignup {
  background-color: #E0E0E0;
}

.navAuthBtnsSignupDarkMode {
  background-color: #858585;
}
