.el {
  margin-bottom: 24px;
  padding-bottom: 32px;
  border-bottom: 1px solid #E0E0E0;
}

.el:last-child {
  border-bottom: none;
}

.elHeading {
  font-size: 20px;
  font-weight: 600;
  color: #3D3D3D;
  padding-bottom: 12px;
}

.elHeadingDarkMode {
  color: #C2C2C2;
}

.elDesc {
  font-size: 16px;
  font-weight: 500;
  color: #858585;
  padding-bottom: 8px;
}

.formElements {
  gap: 8px;
}

.label {
  padding-top: 12px;
  font-size: 14px;
  color: #A3A3A3;
}

.formInputBox {
  width: 25%;
  max-width: 250px;
  min-width: 80px;

  font-size: 16px;
  font-weight: 500;
}

.btn,
.passBtn,
.deleteBtn {
  width: fit-content;
}

.emailInputBox {
  width: 25%;
  max-width: 300px;
  min-width: 220px;

  font-size: 16px;
  font-weight: 500;
}

.emailConfirmation {
  display: flex;
  align-items: center;
  gap: 8px;

  padding-top: 8px;
  padding-bottom: 8px;
}

.emailCheckbox {
  accent-color: #676767;
}

.emailCheckbox:hover {
  cursor: pointer;
}

.emailConfirmationText {
  color: #A3A3A3;
}

.btnDeleteUnconfirmed,
.btnDeleteUnconfirmed:hover {
  cursor: not-allowed;
  color: #FFF;
  background-color: #A3A3A3;
  border-color: #A3A3A3;
}

.passBtn {
  background-color: #CC541D;
}

.passBtn:hover {
  background-color: #FF6924;
}

.passBtn:active {
  background-color: #FF8750;
}

.deleteBtn {
  background-color: #FF2424;
}

.deleteBtn:hover {
  background-color: #FF5050;
}

.deleteBtn:active {
  background-color: #FF7B7B;
}
