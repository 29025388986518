.accountInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.popupOpen {
  color: #FFF;
  fill: #FFF;
  stroke: #FFF;
  background-color: #676767;
  cursor: pointer;

  transition: all 0.25s;
}

.popupOpenDarkMode {
  color: #3D3D3D;
  fill: #3D3D3D;
  stroke: #3D3D3D;
  background-color: #E7E7E7;
}
