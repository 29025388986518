.mainFooter {
  color: #A3A3A3;
  border-top: 1px solid #E0E0E0;
  padding-top: 12px;
  padding-bottom: 64px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainFooter a:link,
.mainFooter a:visited {
  color: #A3A3A3;
  transition: color 0.25s;
}

.mainFooter a:hover {
  color: #525252;
  transition: color 0.25s;
}

.mainFooterDarkMode a:hover {
  color: #FFF;
}

.mainFooterInfo {
  display: flex;
  gap: 16px;

  font-size: 15px;
}

.mainFooterSocial {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}


.mainFooterSocialIcon {
  width: 20px;
  height: 20px;
  fill: #A3A3A3;
  transition: fill 0.25s;
}

.mainFooterSocialIcon:hover {
  fill: #525252;
  transition: fill 0.25s;
}

.mainFooterSocialIconDarkMode:hover {
  fill: #FFF;
}
