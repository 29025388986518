.formElements {
  gap: 8px;
  padding-top: 12px;
}

.heading {
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 24px;
}

.text {
  color: #676767;
  padding-bottom: 4px;
}

.label {
  padding-top: 12px;
  font-size: 14px;
  color: #A3A3A3;
}

 .btn {
  margin-top: 30px;
 }