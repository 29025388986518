/*
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px)
8 / 10 / 13 / 16 / 20 / 25 / 31 / 39 / 49 / 61 / 76

MAIN COLOR: #FF6924 - https://colors.artyclick.com/color-shades-finder/?color=#FF6924
COMP COLOR: #24BAFF - https://colors.artyclick.com/color-shades-finder/?color=#24BAFF
GREY: #676767 - https://colors.artyclick.com/color-shades-finder/?color=#676767

ICONS: https://phosphoricons.com/
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Inter", sans-serif;
}

body {
  color: #3D3D3D;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
  color: inherit;
}
