.errorPage {
  background-image: linear-gradient(rgba(36, 186, 255, 0.08), rgba(255, 105, 36, 0.08));
}

.errorPageDarkMode {
  background-image: linear-gradient(rgba(36, 186, 255, 0.05), rgba(255, 105, 36, 0.05));

  background-color: #141414;
}

.errorPageMain {
  color: #676767;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 80%;
  padding: 0 25%;
}

.message {
  font-size: 48px;
  font-weight: 500;
  line-height: 1.08;
  margin-bottom: 48px;
}

.desc {
  font-size: 18px;
  padding-bottom: 32px;
}

.errorPageFooter {
  height: 10%;

  width: 75%;
}
