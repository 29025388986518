.heading {
  font-size: 20px;
  font-weight: 600;
  color: #3D3D3D;
  padding-bottom: 12px;
}

.headingDarkMode {
  color: #C2C2C2;
}

.desc {
  font-size: 16px;
  font-weight: 500;
  color: #858585;
  padding-bottom: 8px;
}

.categories {
  padding-top: 12px;
  width: fit-content;
}

.mainCategoryContent {
  padding: 16px 0px;
}

.mainCategoryTitle {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 4px;
  background-color: transparent;
}
