.wholeScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup {
  position: absolute;
  top: 56px;
  right: 16%;

  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 7px;
  box-shadow: 0px 4px 12px 5px rgba(0,0,0,0.07);
}

.popupInner {
  position: relative;
  padding: 8px;
  background-color: #FFF;
  border-radius: 7px;

  z-index: 1002;
}

.notificationText {
  padding: 4px;
  padding-right: 48px;

  font-size: 16px;
  font-weight: 600;
  color: #3D3D3D;
}

.divider {
  margin-top: 4px;
  padding-top: 4px;
  border-top: 1px solid #E0E0E0;
}
