.timelogItem {
  display: flex;
  align-items: center;

  border-radius: 4px;
  font-size: 13px;
  padding: 1px 0px;
  font-weight: 600;
  position: relative;
}

.dot {
  padding: 8px;
  background-color: red;
  border-radius: 50px;
  margin-right: 4px;
}

.elCategory {
  width: 120px;
  padding-right: 4px;
}

.elDescription {
  width: 160px;
}

.timeElements {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;
}

.elDuration {
  font-weight: 400;
}

.modifyIcons {
  visibility: hidden;
  display: flex;
  position: absolute;
  padding: 3px;
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid #858585;
  left: 415px;
}

.modifyIconsDarkMode {
  background-color: #0A0A0A;
  border: 1px solid #858585;
}

div.timelogItem:hover .modifyIcons {
  visibility: visible;
}

.icon {
  width: 15px;
  height: 15px;
  fill: #676767;
  box-sizing: content-box;
  padding: 2px 2px;
  border-radius: 5px;
}

.icon:hover {
  background-color: #E0E0E0;
}

.iconDarkMode {
  fill: #E7E7E7;
}

.iconDarkMode:hover {
  background-color: #3D3D3D;
}
