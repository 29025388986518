.mainNav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #676767;
  font-size: 13px;
  font-weight: 500;
  padding: 12px 0px;
}

.myLoggy {
  display: flex;
  align-items: center;
  gap: 8px;
}

.mainNavHome {
  font-size: 16px;

  transition: all 0.25s;
}

.mainNavHome:hover {
  cursor: pointer;
  color: #000;

  transition: all 0.25s;
}

.mainNavHomeDarkMode {
  color: #C2C2C2;
}

.mainNavHomeDarkMode:hover {
  color: #FFF;
}

.logo {
  width: 20px;
  height: 20px;
}

.mainNavAuth {
  text-decoration: none;
  display: flex;

  gap: 12px;
}
