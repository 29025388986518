.helpPage {
  background-color: #FCFCFC;
}

.helpPageDarkMode {
  background-color: #141414;
}

.content {
  max-width: 75%;
  min-width: 300px;
  font-size: 14px;
}

.logHeading {
  font-size: 49px;
  font-weight: 600;
  text-align: center;

  padding-top: 80px;
  padding-bottom: 32px;
}

.logDesc {
  font-size: 15px;
  font-weight: 400;
  text-align: center;

  padding-bottom: 64px;
}

.tlCategory {
  padding: 28px 32px;
  border-radius: 20px;
  margin-bottom: 56px;
}

.tlCategoryHeading {
  font-size: 36px;
  font-weight: 600;
  padding-bottom: 32px;
}

.tlDesc {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 32px;
  text-decoration: underline;
}

.tlExamples {
  font-size: 15px;
  padding: 12px;
}

.tlSubcategory {
  display: flex;
  align-items: center;
  gap: 8px;

  padding-bottom: 4px;
}

.tlListDot {
  padding: 2px;
  border-radius: 50px;
}

.tlSubcategoryHeading {
  font-size: 16px;
  font-weight: 600;
}

.tlSubcategoryDesc{
  font-weight: 400;
}

.calling {
  color: #985278;
  background-color: #F9F3F6;
  border: 2px solid #985278;
}
.tlListDotCalling {
  background-color: #985278;
}
.callingDarkMode{
  color: #5C3249;
  background-color: #C28CAA;
  border: 2px solid #5C3249;
}

.career {
  color: #446A9B;
  background-color: #ECF0F7;
  border: 2px solid #446A9B;
}
.tlListDotCareer {
  background-color: #446A9B;
}
.careerDarkMode {
  color: #29405D;
  background-color: #829EC3;
  border: 2px solid #29405D;
}

.sharpenTheSaw {
  color: #628545;
  background-color: #F0F6EC;
  border: 2px solid #628545;
}
.tlListDotSharpenTheSaw {
  background-color: #628545;
}
.sharpenTheSawDarkMode {
  color: #3B5029;
  background-color: #99B681;
  border: 2px solid #3B5029;
}

.unimportantEssential {
  color: #676767;
  background-color: #F1F1F1;
  border: 2px solid #676767;
}
.tlListDotUnimportantEssential {
  background-color: #676767;
}
.unimportantEssentialDarkMode {
  color: #3D3D3D;
  background-color: #A3A3A3;
  border: 2px solid #3D3D3D;
}

.bullshit {
  color: #EB4242;
  background-color: #FCEBEB;
  border: 2px solid #EB4242;
}
.tlListDotBullshit {
  background-color: #EB4242;
}
.bullshitDarkMode {
  color: #8D2727;
  background-color: #F38A8A;
  border: 2px solid #8D2727;
}
