.icon {
  width: 32px;
  height: 32px;
  fill: #858585;
  background-color:#F0F0F0;

  box-sizing: content-box;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 50%;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 4px;

  color: #525252;
  margin-bottom: 32px;
}

.text h1 {
  font-size: 25px;
  margin-bottom: 8px;
}

.text div {
  font-size: 13px;
}

.signup {
  color: #141414;
  font-weight: 600;
}

.signup:hover {
  text-decoration: underline;
}

.forgotPasswordText {
  font-size: 13px;
  color: #24BAFF;
  font-weight: 600;
  align-self: flex-start;

  transition: all 0.25s;
}

.forgotPasswordText:hover {
  cursor: pointer;
  color: #1D94CC;

  transition: all 0.25s;
}

.emailMain {
  max-width: 288px;
}

.emailHeading {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 12px;
  color: #6E935E;
}

.emailText {
  font-size: 14px;
}

.emailAddress {
  font-weight: 500;
  color: black;
}
