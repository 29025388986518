.category {
  font-size: 15px;
  font-weight: 500;
  padding: 8px 12px;
  margin: 12px 0px;
  border-radius: 6px;
  width: fit-content;
  border: 1px solid #C2C2C2;

  transition: all 0.25s;
}

.category:hover {
  color: #FFF;
  background-color: #676767;
  border: 1px solid #3D3D3D;

  cursor: pointer;

  transition: all 0.25s;
}

.categoryDarkMode {
  border: 1px solid #141414;
}

.categoryDarkMode:hover {
  color: #3D3D3D;
  background-color: #E7E7E7;
}
