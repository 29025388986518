.content {
  max-width: 75%;
  min-width: 300px;
  font-size: 14px;
}

.heading {
  font-size: 49px;
  font-weight: 600;

  padding-top: 80px;
  padding-bottom: 32px;
}

.desc {
  font-size: 15px;
  font-weight: 400;

  padding-bottom: 48px;
}

.free {
  color: black;
  font-weight: 700;
}

.freeDarkMode {
  color: #FFF;
}
