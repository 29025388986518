/* All */

/* ------------------------------------------------- */
/* Page Layout */
/* ------------------------------------------------- */
:where(.plColor) {
  background-image: linear-gradient(rgba(255, 105, 36, 0.08), rgba(36, 186, 255, 0.08));
}

:where(.plPage) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: fit-content;
  min-width: 100%;

  min-height: 100vh;

  background-color: #FFF;
}

:where(.plNav) {
  padding-top: 12px;
  width: 75%;
}

:where(.plContent) {
  margin-bottom: auto;
}

:where(.plFooter) {
  width: 75%;
}


/* ------------------------------------------------- */
/* Tab */
/* ------------------------------------------------- */
:where(.tabHeading) {
  font-size: 32px;
  font-weight: 600;
  color: #3D3D3D;

  padding-top: 32px;
  padding-bottom: 24px;
}

:where(.tabType) {
  display: flex;
  gap: 16px;

  margin-bottom: 32px;
  border-bottom: 1px solid #E0E0E0;
}

:where(.tab) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: #C2C2C2;
  fill: #C2C2C2;
  stroke: #C2C2C2;
  padding-bottom: 8px;
  border-bottom: 2px solid transparent;

  transition: all 0.25s;
}

:where(.tab:hover) {
  color: #3D3D3D;
  fill: #3D3D3D;
  stroke: #3D3D3D;
  cursor: pointer;

  transition: all 0.25s;
}

:where(.tabChosen) {
  color: #3D3D3D;
  fill: #3D3D3D;
  stroke: #3D3D3D;
  border-bottom: 2px solid #3D3D3D;

  transition: all 0.25s;
}


/* ------------------------------------------------- */
/* Icon */
/* ------------------------------------------------- */
:where(.icon) {
  width: 20px;
  height: 20px;
}

.accountIcon {
  color: #676767;
  fill: #676767;
  stroke: #676767;
  background-color: #E7E7E7;
  border-radius: 50px;
  box-sizing: content-box;
  padding: 4px 4px;

  transition: all 0.25s;
}

.accountIcon:hover {
  color: #FFF;
  fill: #FFF;
  stroke: #FFF;
  background-color: #676767;
  cursor: pointer;

  transition: all 0.25s;
}


/* ------------------------------------------------- */
/* Buttons */
/* ------------------------------------------------- */
:where(.btnPopup) {
  align-self: center;
  width: 49%;
  font-weight: 600;
  padding: 8px 12px;
  margin-top: 12px;
  border-radius: 8px;

  transition: all 0.25s;
}

:where(.btnPrimary) {
  color: #FFF;
  background-color: #3D3D3D;
  border: 1px solid #3D3D3D;
  cursor: pointer;
}

:where(.btnPrimary:hover) {
  color: #F0F0F0;
  background-color: #525252;
  border: 1px solid #3D3D3D;
}

:where(.btnSecondary) {
  color: #525252;
  background-color: #F0F0F0;
  border: 1px solid #C2C2C2;
}

:where(.btnSecondary:hover) {
  color: #292929;
  background-color: #E0E0E0;
  border: 1px solid #C2C2C2;
  cursor: pointer;
}

:where(.btnDelete) {
  background-color: #FF2424;
  border: 1px solid #FF2424;
}

:where(.btnDelete:hover) {
  background-color: #FF5050;
  border: 1px solid #FF5050;
}



/* ------------------------------------------------- */
/* TimeLog */
/* ------------------------------------------------- */
/* -------------------------- */
/* Form */
/* -------------------------- */
:where(.formElements) {
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-bottom: 12px;
}

:where(.formInput) {
  display: flex;
  flex-direction: column;
  gap: 4px;

  color: #858585;
}

:where(.formInputBox) {
  color: #141414;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding: 8px 12px;
  border: 1px solid #C2C2C2;
  border-radius: 8px;

  transition: all 0.25s;
}

:where(.formInputBox:hover) {
  border: 1px solid #858585;
  cursor: pointer;

  transition: all 0.25s;
}

:where(.formInputBox:focus) {
  outline: none;
  background-color: #fff;
  border: 1px solid #292929;

  transition: all 0.25s;
}

:where(.formInputBox::placeholder) {
  color: #C2C2C2;
}

.formBtn {
  position: relative;
  border: none;
  padding: 8px 12px;
  background-color: #3D3D3D;
  border-radius: 8px;
  margin-top: 12px;
  color: #FFF;

  transition: all 0.25s;
}

.formBtn:hover {
  background-color: #525252;
  cursor: pointer;

  transition: all 0.25s;
}

.formBtn:active {
  background: #676767;
}

.formBtnText {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #FFF;
  transition: all 0.25s;
}

.formBtnLoading .formBtnText {
  visibility: hidden;
  opacity: 0;
}

.formBtnLoading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s linear infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

:where(.formNotificationText) {
  font-size: 15px;
  font-weight: 500;
  color: #FF2424;
  align-self: center;
  padding-top: 8px;
  text-align: center;
}


/* -------------------------- */
/* Config */
/* -------------------------- */
:where(.tlConfigBtn) {
  font-family: "Inter", sans-serif;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  margin-top: 12px;

  transition: all 0.25s;
}

:where(.tlConfigBtn:hover) {
  cursor: pointer;

  transition: all 0.25s;
}


/* -------------------------- */
/* Colors */
/* -------------------------- */
:where(.dropdownText) {
  font-weight: 600;
}

:where(.tlCalling) {
  color: #985278;
  background-color: #EBD5E1;
}

:where(.tlCareer) {
  color: #446A9B;
  background-color: #D1DCEB;
}

:where(.tlSharpenTheSaw) {
  color: #628545;
  background-color: #DAE7D0;
}

:where(.tlUnimportantEssential) {
  color: #676767;
  background-color: #E0E0E0;
}

:where(.tlBullshit) {
  color: #EB4242;
  background-color: #FBD7D7;
}


/* ------------------------------------------------- */
/* Dark Mode */
/* ------------------------------------------------- */
.plColorDarkMode {
  background-image: linear-gradient(rgba(255, 105, 36, 0.05), rgba(36, 186, 255, 0.05));
}

.plPageDarkMode {
  background-color: #141414;
}

.darkMode {
  color: #C2C2C2;
  background-color: #141414;
}

/* Icon */
.accountIconDarkMode {
  color: #E7E7E7;
  fill: #E7E7E7;
  stroke: #E7E7E7;
  background-color: #676767;
}

.accountIconDarkMode:hover {
  color: #3D3D3D;
  fill: #3D3D3D;
  stroke: #3D3D3D;
  background-color: #E7E7E7;
}

/* Tab */
.tabHeadingDarkMode {
  color: #C2C2C2;
}

.tabTypeDarkMode {
  border-bottom: 1px solid #525252;
}

.tabDarkMode {
  color: #525252;
  fill: #525252;
  stroke: #525252;
}

.tabDarkMode:hover {
  color: #C2C2C2;
  fill: #C2C2C2;
  stroke: #C2C2C2;
}

.tabChosenDarkMode {
  color: #C2C2C2;
  fill: #C2C2C2;
  stroke: #C2C2C2;
  border-bottom: 2px solid #C2C2C2;
}

/* Buttons */
.btnPrimaryDarkMode {
  color: #525252;
  background-color: #F0F0F0;
  border: 1px solid #F0F0F0;
}

.btnPrimaryDarkMode:hover {
  color: #292929;
  background-color: #C2C2C2;
  border: 1px solid #C2C2C2;
}

.btnSecondaryDarkMode {
  color: #E0E0E0;
  background-color: #3D3D3D;
  border: 1px solid #E0E0E0;
}

.btnSecondaryDarkMode:hover {
  color: #C2C2C2;
  background-color: #525252;
  border: 1px solid #C2C2C2;
}

/* TimeLog Form */
.formInputBoxDarkMode {
  color: #C2C2C2;
  background-color: #1F1F1F;
}

/* Colors  */
:where(.tlCallingDarkMode) {
  color: #5C3249;
  background-color: #C28CAA;
}

:where(.tlCareerDarkMode) {
  color: #29405D;
  background-color: #829EC3;
}

:where(.tlSharpenTheSawDarkMode) {
  color: #3B5029;
  background-color: #99B681;
}

:where(.tlUnimportantEssentialDarkMode) {
  color: #3D3D3D;
  background-color: #A3A3A3;
}

:where(.tlBullshitDarkMode) {
  color: #8D2727;
  background-color: #F38A8A;
}
