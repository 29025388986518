.timelogWeek {
  padding-top: 48px;
  padding-bottom: 24px;
  margin-top: 48px;
  border-top: 1px solid #676767;
}

.timelogDays {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
