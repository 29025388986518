.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(194, 194, 194, 0.75);

  z-index: 1001;   /* Makes sure nothing behing popup can be clicked or seen */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupDarkMode {
  background-color: rgba(20, 20, 20, 0.75);
}

.popupInner {
  position: relative;
  padding: 32px;
  background-color: #FFF;
  border: 1px solid #A3A3A3;
  border-radius: 20px;

  z-index: 1002
}

.popupInnerDarkMode {
  background-color: #1F1F1F;
  border: 1px solid #E7E7E7;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notificationText {
  color: #FF2424;
  align-self: center;
  padding-top: 12px;
}
