.tabHeading {

  width: 455px;
}

.tabType {
  width: 452px;
}

.logs {
  flex: 1;

  padding: 80px 12px;
  padding-top: 32px;
}
