.config {
  display: flex;
  flex-direction: column;
  flex-basis: content;
}

.line {
  display: flex;
  justify-content: flex-start;

  gap: 19px;
  padding: 0px 8px;
  padding-bottom: 24px;
}

.btn {
  font-size: 13px;
  margin: 0;
}

.viewBtns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  position: relative;

  background-color: #F0F0F0;
  border: 1px solid #C2C2C2;
  padding: 2px;
  border-radius: 10px;
}

.viewBtnsDarkMode {
  background-color: #3D3D3D;
  border: 1px solid #C2C2C2;
}

.viewBtn {
  border: none;
  /* color: #FFF;
  background-color: #3D3D3D; */
  color: #FFF;
  background-color: #A3A3A3;
}

.viewBtnDarkMode {
  /* color: #3D3D3D;
  background-color: #E7E7E7; */
  color: #292929;
  background-color: #858585;
}

.notSelected {
  color: #A3A3A3;
  background-color: #F3F3F3;
}

.notSelected:hover {
  color: #3D3D3D;
}

.notSelectedDarkMode {
  color: #A3A3A3;
  background-color: #3D3D3D;
}

.notSelectedDarkMode:hover {
  color: #E0E0E0;
}

.iconBtn {
  position: relative;

  padding: 8px 8px;
  border-radius: 24px;

  transition: all 0.25s;
}

.addButtonPrimaryDarkMode:hover svg {
  fill: #3D3D3D;
}

.addButtonSecondaryDarkMode:hover svg {
  fill: #C2C2C2;
}

.addButtonText {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.addIcon {
  width: 20px;
  height: 20px;

  transition: all 0.25s;
}

.addIconPrimary {
  fill: #FFF;
}
.addIconPrimaryDarkMode {
  fill: #525252;
}
.addIconSecondary {
  fill: #525252;
}
.addIconSecondaryDarkMode {
  fill: #E7E7E7;
}

.dateSelect {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  padding: 8px 12px;
  border-radius: 8px;

  transition: all 0.2s;
}

/* Tooltips */
.iconBtn .viewTooltip,
.iconBtn .statisticsTooltip,
.viewBtns .viewTooltip {
  white-space: nowrap;
  text-align: center;
  top: -52px;
  left: 14px;
  padding: 8px 12px;
  color: #525252;
  background-color: #F0F0F0;
  border: 1px solid #C2C2C2;
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  z-index: 1000;

  display: none;
}

.iconBtn:hover .viewTooltip,
.iconBtn:hover .statisticsTooltip,
.viewBtns:hover .viewTooltip {
  display:block;
}

.iconBtn .viewTooltip i,
.iconBtn .statisticsTooltip i,
.viewBtns .viewTooltip i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  width: 30px;
  height: 15px;
  overflow: hidden;
}

.iconBtn .viewTooltip i::after,
.iconBtn .statisticsTooltip i::after,
.viewBtns .viewTooltip i::after {
  content:'';
  position:absolute;
  width: 8px;
  height: 8px;
  left: 50%;
  transform: translate(-50%,-50%) rotate(45deg);
  background-color: #F0F0F0;
  border: 1px solid #C2C2C2;
}

.iconBtn .viewTooltip {
  left: -39px;
}

.iconBtn .statisticsTooltip {
  left: -27px;
}
