.settingsPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: auto;
  min-height: 100vh;
  width: fit-content;
  min-width: 100%;

  background-color: #F6FCFF;
}

.settingsPageDarkMode {
  background-color: #141414;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.contentDisplayed {
  flex: 1;

  padding-top: 48px;
  padding-bottom: 80px;
  width: 75%;
}
